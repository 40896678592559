import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="https://www.amazon.com/Quarter-Decoration-Display-Compatible-Window-Infinity/dp/B0DQJYKWBQ/ref=sr_1_1?dib=eyJ2IjoiMSJ9.-nTZ70HZ7mcstXVWen6YO0-uSM5lBzVaqCGXmY8_xSU1J2Gtl7DlwhKjBS0Aa3vqsnZKXfqSSSYkave-9lglC6afgLs_VAvzAac16p3JLRC3yBVSiJMw7XH7kz6fnush.JnmfjVKFm_UxzXckjWanxP2IMYzJ8AUGtsWV9gAulR4&dib_tag=se&keywords=Auturo&qid=1735595735&sr=8-1&th=1"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Shop Now
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
